import React, { useState } from "react"
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider  } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTiktok, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import Container from '@mui/material/Container';
import bgHero0 from '../images/bg-hero-0.png';
import bgHero1 from '../images/bg-hero-1.png';
import bgHero2 from '../images/bg-hero-2.png';
import './styles.scss';

const theme = createTheme({
  typography: {
    fontFamily: [
      '"Gilroy"',
      'sans-serif'
    ].join(','),
    h1: {
      fontWeight: 900,
      textTransform: 'uppercase',
    },
    h2: {
      fontWeight: 900,
      textTransform: 'uppercase',
    },
    h3: {
      fontWeight: 900,
      textTransform: 'uppercase',
    },
    h4: {
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    p: {
      fontSize: '1.2rem',
      lineHeight: '1.7rem',
      paddingBottom: '1rem'
    }
  },
});

const bg_images = [bgHero0, bgHero1, bgHero2];
// markup
const IndexPage = () => {
  const [ activeDrawer, setActiveDrawer] = useState(0);

  const onClickDrawer = (index) => () => {
    setActiveDrawer(index)
  }
  return (
    <ThemeProvider theme={theme}>
        <nav>
          <div className="title">
            <Typography variant="h6" component="div" className="title">
              Reborn Again
            </Typography>
          </div>
          <div>
            <a href="https://twitter.com/rebornagainart" target="_blank"><FontAwesomeIcon icon={faTwitter} /></a>
            <a href="https://www.instagram.com/misterjeyofficial/" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a>
            <a href="https://www.tiktok.com/@misterjeyofficial" target="_blank"><FontAwesomeIcon icon={faTiktok} /></a>
          </div>
        </nav>
        <section
          className="hero"
          style={{backgroundImage: `url(${bg_images[Math.floor(Math.random() * 2)]})`}}
        >
          <Typography variant="h1" component="h1" >
            Reborn Again
          </Typography>
          <Typography variant="h4" component="div" >
            Children, Art, NFTs & Charity
          </Typography>
        </section>
        <Container maxWidth="md">
          <Box sx={{padding:'4rem 0 2rem'}}>
            <Typography variant="h3" component="h3">
                What is Reborn Again?
              </Typography>
              <Typography variant="p" component="p">
                Helping children in need by converting their art into NFTs. The NFTs are then sold and the profit will be use to support existing project or ONG. 
              </Typography>
            </Box>
          <Box sx={{padding:'4rem 0 2rem 0'}}>
            <Typography variant="h3" component="h3">
              Roadmap
            </Typography>
            <Typography variant="p" component="p">
              <ul>
                <li>Identify the first cause to support</li>
                <li>Create a content strategy for social media</li>
                <li>Start creating NFTs</li>
                <li>Generate profit by selling the NFTs</li>
                <li>Use the profit to help the cause</li>
                <li>Apply the same thing to another cause</li>
              </ul>
            </Typography>
          </Box>

          <Box sx={{padding:'2rem 0'}}>
            <Typography variant="h3" component="h3">
              FAQ
            </Typography>

            <Box mb={2}>
              <Typography variant="h5" component="h3">Why choosing NFT's for this project?</Typography>
              <Typography variant="p">We are currently living the biggest technological revolution we ever seen. The world is changing right now and we don't want to be left behind. NFTs have unlimited potential and it's up to us to defined how we will use it in the future.</Typography>
            </Box>
            <Box mb={2}>
              <Typography variant="h5" component="h3">Orphans and other children without biological families</Typography>
              <Typography variant="p">WNFT stands for Non-fungible token. “Non-fungible” more or less means that it’s unique and can’t be replaced with something else. </Typography>
              <Typography variant="p">Imagine a painting: there is only one physical object. Digital “things” can’t have the same kind of uniqueness, because every version of a jpg is the same, there is no “original”. </Typography>
              <Typography variant="p">So NFT is a technology to create a kind of certificate of uniqueness that is tied to any object, image, sound or anything really. It’s like a notarized ownership certificate for whatever you want. </Typography>
            </Box>
            <Box mb={2}>
              <Typography variant="h5" component="h3">Is it like BitCoin?</Typography>
              <Typography variant="p">A bitcoin is fungible — trade one for another bitcoin, and you’ll have exactly the same thing. A one-of-a-kind trading card, however, is non-fungible. If you traded it for a different card, you’d have something completely different. </Typography>
            </Box>
            <Box mb={2}>
              <Typography variant="h5" component="h3">How much do NFTs cost?</Typography>
              <Typography variant="p">Each NFT is unique, so they all have different values. Like with any asset, its price is determined by demand and supply.</Typography>
              <Typography variant="p">Since there is only one of each NFT, the scarcity, combined with the trend towards digital collectibles, is resulting in some of them being sold for a lot of money.</Typography>
              <Typography variant="p">For example, an NFT for a picture of a cat was sold by Paris Hilton for around $17,000. The most expensive NFT so far is a piece of digital art sold for over sixty nine million dollars.</Typography>
            </Box>

            <Box mb={2}>
              <Typography variant="h5" component="h3">How are NFTs created?</Typography>
              <Typography variant="p">If you want to create an NFT, you have to first choose a specific blockchain technology, and upload your content to that blockchain… or ask Mister Jey to do it for you.</Typography>
              <Typography variant="p">Then, you select the NFT marketplace to list your NFT on, since NFTs can only be bought and sold on specialized NFT marketplaces.</Typography>
              <Typography variant="p">You can even add a royalty or commission to your NFT, which would result in a payment to you every time your NFT changes hands.</Typography>
            </Box>

            <Box mb={2}>
              <Typography variant="h5" component="h3">How are NFTs bought & sold?</Typography>
              <Typography variant="p">In order to buy or sell an NFT, you need a digital wallet specific to that marketplace, which needs to be loaded with the cryptocurrency accepted at that marketplace. But, then again, Mister Jey got you covered. He will do it and send you the profit directly to your bank account.</Typography>
            </Box>
          </Box>

          
        </Container>
    </ThemeProvider>
  )
}

export default IndexPage
